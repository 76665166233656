const firebase = require("firebase/app");

require("firebase/auth");
require("firebase/database");

const firebaseConfig = {
  apiKey: "AIzaSyDso-UJCqOJPDVMKIfn6_xLWEHVenDQG-I",
  authDomain: "schedule-20022.firebaseapp.com",
  databaseURL: "https://schedule-20022.firebaseio.com",
  projectId: "schedule-20022"
};
firebase.initializeApp(firebaseConfig);

export const getDatabase = () => {
  const db = firebase.database();
  return db;
};
export const getAuth = () => {
  const auth = firebase.auth();
  return auth;
};
